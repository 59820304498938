import React, { useState } from "react";
import { graphql } from 'gatsby';
import { Content } from "components/pages/contact-us/content";
import { Modal } from "components/pages/contact-us/modal";
import { Form } from "components/pages/contact-us/form";

const ContactUsPage = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <main className="flex flex-col justify-center items-center -mt-28 sm:-mt-36 relative">
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} />

      <section className='relative w-full mx-auto flex px-6 pt-36 pb-16 lg:pt-60 lg:pb-24  overflow-hidden'>
        {/* BACKGROUND IMAGE */}
        <div className="w-full left-0 top-0 bg-contact-mobile bg-cover lg:bg-contact-desktop absolute h-full lg:h-[50%] z-[-1]">
        </div>

        <div className='flex w-full flex-col lg:flex-row lg:gap-16 max-w-screen-2xl mx-auto lg:px-36'>
          <Content />
          <Form setModalOpen={setModalOpen} />
        </div>
      </section>
    </main>
  );
};

export default ContactUsPage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 