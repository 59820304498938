import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next'

type P = {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const Modal = ({ modalOpen, setModalOpen }: P) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (modalOpen) document.body.style.overflow = "hidden";
    if (!modalOpen) document.body.style.overflow = "";
  }, [modalOpen])

  if (!modalOpen) return null

  return (
    <div className={`flex justify-center items-center fixed z-[200] left-0 top-0 w-full h-full overflow-auto bg-black/50`}>
      <div className="flex flex-col gap-8 w-[95%] max-w-[400px] font-work-sans rounded-md shadow bg-neutral p-8">
        <div className='flex flex-col gap-2'>
          <h3 className="text-2xl">
            {t('contact-us.modal.title')}
          </h3>

          <p className="">
            {t('contact-us.modal.desc')}
          </p>
        </div>

        <button
          onClick={() => setModalOpen(false)}
          className="p-2 text-center border border-neutral-400"
        >
          {t('common.cta.okay')}
        </button>
      </div>
    </div>
  );
};