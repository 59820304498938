import React from "react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

export const Content = () => {
	const { t } = useTranslation()

	return (
		<div className="flex flex-col justify-between w-full py-20 gap-8">
			<div className="w-full flex flex-col gap-2 text-white">
				<h1 className="text-5xl lg:text-[68px] font-idealist-sans">
					{t("common.words.contact-us")}
				</h1>
				<p className="text-lg lg:text-[22px]">
					{t("contact-us.content.question-for-us")}
					<br />
					<Trans
						i18nKey="contact-us.content.check-resources"
						components={{
							anchor: <Link to="/support" />,
						}}
					/>
				</p>
			</div>

			<div className="hidden flex-col gap-4 lg:flex">
				<img
					loading="eager"
					src="/images/logo.png"
					alt="innovation icon"
					className="w-16"
				/>

				<div className="text-xl text-theme-orange">{t("contact-us.content.company-name")}</div>

				<p className="flex flex-col">
					{process.env.GATSBY_LANGUAGE_SELECTED !=='ja' && <span>{t("contact-us.content.uen")}</span>}
					<span>{t("contact-us.content.address1")}</span>
					<span>{t("contact-us.content.address2")}</span>
				</p>

				<a
          href={`mailto: ${t("contact-us.content.mail")}`}
          className="text-theme-blue flex"
        >
          {t("contact-us.content.mail")}
        </a>
			</div>
		</div>
	)
}
